import * as UBUtils from "./modules/ub-tracker-utils";
import {
    UBTrackerInterface,
    UBActionType,
    ActionType,
    EndpointType,
    TrackTargetTrackEventInterface
} from "./ub-tracker-core.d";

const UBTracker = {
    account: 'speisekarte',
    storagePrefix: 'ubtracker_',
    pageViewed: false,

    UBUtils,

    init: function (): void {
        UBTracker.UBUtils.getSessionId();
        UBTracker.UBUtils.getClientId();
    },

    bind(ubAction: UBActionType, action: ActionType, category: string, label: string): UBTrackerInterface {
        return {
            page: UBTracker.UBUtils.getPage(),
            category: category,
            action: action,
            label: label,
            device_category: UBTracker.UBUtils.getDeviceCategory(),
            ubact: ubAction,
            ubcl: UBTracker.UBUtils.getClientId(),
            ubses: UBTracker.UBUtils.getSessionId(),
            ubref: UBTracker.UBUtils.getReferrer(),
        }
    },

    send: function(data: UBTrackerInterface): void {
        navigator.sendBeacon('/ubtracker', JSON.stringify(data));
    },

    trackEvent: {
        click: function (category: string, label: string): void {
            const data: UBTrackerInterface = UBTracker.bind('trackEvent', 'click', category, label);
            UBTracker.send(data);
        },

        view: function (category: string, label: string): void {
            const data: UBTrackerInterface = UBTracker.bind('trackEvent', 'view', category, label);
            UBTracker.send(data);
        },

        attached: function (category: string, label: string): void {
            const data: UBTrackerInterface = UBTracker.bind('trackEvent', 'attached', category, label);
            UBTracker.send(data);
        },
    },

    trackPageView: function (): void {
        if (UBTracker.pageViewed) {
            console.warn('This method was already executed. If you want to track a page view again, please reload the page.');
            return;
        }

        UBTracker.pageViewed = true;

        const data: UBTrackerInterface = UBTracker.bind('trackPageView', 'view', '', '');
        UBTracker.send(data);
    },

    trackTarget: function (target: any, trackEvent: TrackTargetTrackEventInterface): void {
        if (!target) {
            console.error('The target element is not found.');
            return;
        }

        if (typeof target === 'string') {
            target = document.querySelector(target);
        }

        if (trackEvent.click) {
            target.addEventListener('click', function(): void {
                UBTracker.trackEvent.click(trackEvent.click.category, trackEvent.click.label);
            });
        }

        return;
        // This part will be implemented later. First, we need to implement the click event.
        //
        // // TODO: Add the view event handler.
        // if (trackEvent.view) {
        //     UBTracker.trackEvent.view(trackEvent.view.category, trackEvent.view.label);
        // }
        //
        // // TODO: Add the attached event handler.
        // if (trackEvent.attached) {
        //     UBTracker.trackEvent.attached(trackEvent.attached.category, trackEvent.attached.label);
        // }
    },

    onTrackTargetClick: function (target: HTMLElement): void {
        const category: string = target.dataset.ubCategory || '';
        const label: string = target.dataset.ubLabel || '';

        UBTracker.trackEvent.click(category, label);
    }
}

export default UBTracker;