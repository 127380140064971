import { customAlphabet } from 'nanoid';

const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function randomId(size: number = 21): string {
    const nanoid:(size?:number|undefined) => string = customAlphabet(CHARS, size);
    return nanoid();
}

export {
    randomId
};