import {v4 as uuidv4} from "uuid";
import {randomId} from "./random";

function isMobile(): boolean {
    return window.matchMedia('only screen and (max-width: 767px)').matches;
}

function getClientId(): string {
    const clientId: string|null = window.localStorage.getItem('client_id');

    if (!clientId) {
        const newClientId: string = getUUID();

        window.localStorage.setItem('client_id', newClientId);

        return newClientId;
    }

    return clientId;
}

function getSessionId(): string {
    const sessionId: string | null = window.sessionStorage.getItem('session_id');

    if (!sessionId) {
        const newSessionId: string = getUUID();

        window.sessionStorage.setItem('session_id', newSessionId);

        return newSessionId;
    }

    return sessionId;
}

/** @deprecated This function will be removed in future versions. */
function getRandomId(length: number = 5): string {
    return randomId(length);
}

function getUUID(): string {
    return uuidv4();
}

function getDeviceCategory(): string {
    return isMobile() ? 'mobile' : 'desktop';
}

function getReferrer(): string {
    return document.referrer;
}

function getPage(): string {
    return window.location.href;
}

/** @deprecated This function will be removed in future versions. */
function getStatusCode(): number {
    return document.readyState === 'complete' ? 200 : 0;
}

export {
    isMobile,
    getClientId,
    getSessionId,
    getRandomId,
    getUUID,
    getDeviceCategory,
    getReferrer,
    getPage,
    getStatusCode
};